let apiUrl,
  authUrl,
  imagesUrl,
  metabaseUrl,
  authenticationGrantType,
  adminClientId,
  adminClientSecret,
  brandClientId,
  brandClientSecret,
  brandTiktokClientKey,
  brandTiktokRedirectUri;

// Geliştirme ortamı için statik veriler
if (process.env.NODE_ENV === "development") {
  apiUrl = "https://api.dev.soty.io";
  authUrl = "https://auth.dev.soty.io";
  imagesUrl = "https://images.dev.soty.io";
  metabaseUrl = "https://metabase.dev.soty.io";
  authenticationGrantType = "password";
  adminClientId = "admin-client";
  adminClientSecret = "Vkkjk2yPSI1c5C631wa9mWdO5cqUdF8p";
  brandClientId = "brand-client";
  brandClientSecret = "WWM6AhMUIUUyqOSET6jXAOSDGxGUq01W";
  brandTiktokClientKey = "dev-tiktok-key";
  brandTiktokRedirectUri = "/brand/settings/profile";
} else {
  // Üretim ortamı için environment değişkenlerinden okuma
  apiUrl = "https://api.dev.soty.io";
  authUrl = "https://auth.dev.soty.io";
  imagesUrl = "https://images.dev.soty.io";
  metabaseUrl = "https://metabase.dev.soty.io";
  authenticationGrantType = "password";
  adminClientId = "admin-client";
  adminClientSecret = "Vkkjk2yPSI1c5C631wa9mWdO5cqUdF8p";
  brandClientId = "brand-client";
  brandClientSecret = "WWM6AhMUIUUyqOSET6jXAOSDGxGUq01W";
  brandTiktokClientKey = "sbaw5pl25vvg8nveg7";
  brandTiktokRedirectUri = "/brand/settings/profile";
}

const loadConfig = async () => {
  if (process.env.NODE_ENV !== "development") {
    // Yalnızca geliştirme dışı ortamlarda config.json'ı yükler
    const response = await fetch("/config.json");
    if (response.ok) {
      const data = await response.json();
      apiUrl = data.apiUrl || apiUrl;
      authUrl = data.authUrl || authUrl;
      imagesUrl = data.imagesUrl || imagesUrl;
      metabaseUrl = data.metabaseUrl || metabaseUrl;
      authenticationGrantType =
        data.authenticationGrantType || authenticationGrantType;
      adminClientId = data.adminClientId || adminClientId;
      adminClientSecret = data.adminClientSecret || adminClientSecret;
      brandClientId = data.brandClientId || brandClientId;
      brandClientSecret = data.brandClientSecret || brandClientSecret;
    }
  }
};

export {
  apiUrl,
  authUrl,
  imagesUrl,
  metabaseUrl,
  authenticationGrantType,
  adminClientId,
  adminClientSecret,
  brandClientId,
  brandClientSecret,
  brandTiktokClientKey,
  brandTiktokRedirectUri,
  loadConfig,
};
