import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from 'react-router-dom';
import arrow from "../../../../../../assets/media/arrow.png";
import downArrow from "../../../../../../assets/media/down-arrow.png";
import "../../../../../../assets/css/ChallengeDetail.css";
import FilterButton from "../../../../../button/filterButton";
import DatePickerButton from "../../../../../button/datePickerButton";
import EndChallengeButton from "../../../../../button/endChallengeButton";
import ChallengeAnalysisButton from "../../../../../button/challengeAnalysisButton";
import { getCookie } from "../../../../auth/tokenUtils";
import axios from "axios";
import GridListSwitchButton from "../../../../../button/gridListSwitchButton";
import Loader from "../../../../../popup/loader";
import TotalCards from "../../../../../cards/TotalCards";
import DynamicTable from "../../../../../tables/DynamicTable";
import SearchIcon from '../../../../../../assets/media/SearchIcon.svg';
import CampaignCard from "../../../../../cards/campaignCard";
import { metabaseUrl } from '../../../../../../config';

const CorporationCampaignDetail = () => {

    const [viewType, setViewType] = useState('GRID');
    const { t, i18n } = useTranslation("challengeDashboard");
    const location = useLocation();
    const navigate = useNavigate();
    const token = getCookie("access_token");
    let campaignID = location.state?.id;
    const [loaderState, setLoaderState] = useState(false);
    const [uuid, setUuid] = useState(false);
    const [refundedFilter, setRefundedFilter] = useState(false);
    const [campaignsList, setCampaignsList] = useState();
    const [campaigns, setCampaigns] = useState();
    const [headerValues, setHeaderValues] = useState(false);
    const [filterStartDate, setFilterStartDate] = useState();
    const [filterEndDate, setFilterEndDate] = useState();



    const campaignColumns = [
        { title: t('id'), field: "sotierId", className: "" },
        { title: t('nameSurname'), field: "sotierName", className: "" },
        { title: t('corporationName'), field: "corporationName", className: "" },
        { title: t('paymentAmount'), field: "paymentAmount", className: "" },
        { title: t('refundAmount'), field: "refundAmount", className: "" },
        { title: t('sotyCoinRefundAmount'), field: "sotyCoinRefundAmount", className: "" },
        { title: t('participationStatus'), field: "participationStatus", className: "" },
        { title: t('usageDate'), field: "usageDate", className: "" },
    ];





    const handleViewSelect = (viewType) => {
        if (viewType === 'LIST') {

            setViewType('LIST');
            // Burada list görünümüne geçilecek işlemleri yapabilirsiniz.
        } else if (viewType === 'GRID') {

            setViewType('GRID');
            // Burada grid görünümüne geçilecek işlemleri yapabilirsiniz.
        }
    };


    const fetchTopData = async () => {

        const url = `${metabaseUrl}/api/public/card/76155d1c-e236-4d70-bf36-85835806e05d/query/json?parameters=[{"type":"category","target":["variable",["template-tag","campaign_id"]],"value":"${campaignID}"},{"type":"category","target":["variable",["template-tag","start_date"]],"value":"${filterStartDate ? filterStartDate : "2020-01-01T23:59:59"}"},{"type":"category","target":["variable",["template-tag","end_date"]],"value":"${filterEndDate ? filterEndDate : "2100-01-01T23:59:59"}"}]`;

        try {
            const response = await axios.get(url);

            const dataSets = [
                {
                    headerTitle: t('total'),
                    title: t("income"),
                    subtitle: t("TG"),
                    data: response.data[0].total_amount === null ? '0' : response.data[0].total_amount
                },
                {
                    headerTitle: t('total'),
                    title: t("refund_amount"),
                    subtitle: t("RA"),
                    data: response.data[0].refund_amount === null ? '0' : response.data[0].refund_amount
                },
                {
                    headerTitle: t('total'),
                    title: t("invited_users"),
                    subtitle: t("IU"),
                    data: response.data[0].invited_users === null ? '0' : response.data[0].invited_users
                },
                {
                    headerTitle: t('total'),
                    title: t("participation_count"),
                    subtitle: t("PC"),
                    data: response.data[0].participation_count === null ? '0' : response.data[0].participation_count
                },
                ,
                {
                    headerTitle: t('total'),
                    title: t("earned_soty_coin"),
                    subtitle: t("ESC"),
                    data: response.data[0].earned_soty_coin === null ? '0' : response.data[0].earned_soty_coin
                }
            ]

            setHeaderValues(dataSets);

            // Do something with the fetched data here
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const fetchListData = async () => {
        setLoaderState(true);

        const url = `${metabaseUrl}/api/public/card/0b12f326-852c-47a4-bbb5-82161e934f14/query/json?parameters=[{"type":"category","target":["variable",["template-tag","campaign_id"]],"value":"${campaignID}"},{"type":"category","target":["variable",["template-tag","refunded"]],"value":"${refundedFilter}"},{"type":"category","target":["variable",["template-tag","start_date"]],"value":"${filterStartDate ? filterStartDate : "2022-01-01T23:59:59"}"},{"type":"category","target":["variable",["template-tag","end_date"]],"value":"${filterEndDate ? filterEndDate : "2099-01-01T23:59:59"}"}]`;
        console.log(url)
        try {
            const response = await axios.get(url, {});
            console.log(response)
            setCampaigns(response.data);

            const transformedData = response.data.map(item => {
                const campaign = item;




                return {
                    sotierId: campaign.sotier_id,
                    sotierName: t(campaign.first_name) + " " +  campaign.last_name,
                    corporationName: campaign.corporate_name,
                    paymentAmount: campaign.payment_amount,
                    refundAmount: campaign.sotier_refund_amount,
                    sotyCoinRefundAmount: campaign.sotier_refund_coin_amount,
                    participationStatus: t(campaign.participation_status),
                    usageDate: campaign.usage_date
                };
            });
            setCampaignsList(transformedData);

            setLoaderState(false);

            // Do something with the fetched data here
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoaderState(false);

        }




    };

    const checkboxData = [
        { title: t("refundedUsers"), state: 'true' },

    ];

    const handleFilterChange = (e) => {
        console.log("help")
        if( e && e[0] && e[0].state === 'true'){
            setRefundedFilter('true')
            console.log('true')
        }
        else{
            setRefundedFilter('false')
            console.log('false')
        }
        
    }

    

    const handleFilterDatesChanged = ({ selectedStartDate, selectedEndDate }) => {

        if (selectedEndDate) {



            const originalStartDate = new Date(selectedStartDate);
            const originalEndDate = new Date(selectedEndDate);

            originalStartDate.setHours(0, 0, 0);
            originalEndDate.setHours(23, 59, 59);

            const formattedStartDate = `${originalStartDate.getFullYear()}-${(originalStartDate.getMonth() + 1).toString().padStart(2, '0')}-${originalStartDate.getDate().toString().padStart(2, '0')}T${originalStartDate.getHours().toString().padStart(2, '0')}:${originalStartDate.getMinutes().toString().padStart(2, '0')}:${originalStartDate.getSeconds().toString().padStart(2, '0')}`;
            const formattedEndDate = `${originalEndDate.getFullYear()}-${(originalEndDate.getMonth() + 1).toString().padStart(2, '0')}-${originalEndDate.getDate().toString().padStart(2, '0')}T${originalEndDate.getHours().toString().padStart(2, '0')}:${originalEndDate.getMinutes().toString().padStart(2, '0')}:${originalEndDate.getSeconds().toString().padStart(2, '0')}`;

            setFilterStartDate(formattedStartDate);
            setFilterEndDate(formattedEndDate);
        }
        else {
            setFilterStartDate();
            setFilterEndDate();
        }


    }

    useEffect(() => {
        if(campaignID){
            fetchListData();
            fetchTopData();
        }
   
    }, [campaignID, refundedFilter, filterEndDate])

    return (
        <div className="dashboard-container">
            {loaderState && <Loader />}

            <div className="content-location-container">
                <a className="content-location-container-href" href="#">
                    <img src={arrow} alt="arrow" />
                </a>
                <a className="content-location-container-link-text" href="#">
                    <div className="older">{t("corporateCampaigns")}</div>
                </a>
                <img src={downArrow} className="backArrow" alt="down-arrow" />
                <div className="current">{t("corporateCampaignsDetails")}</div>
            </div>
            <div className="challenge-detail-id-container">
                {campaignID && (
                    <div className="challenge-detail-id">
                        {t("campaignId")}: {campaignID}
                    </div>
                )}
            </div>
            <div className='dashboard-top-divider corporate-campaign-dashboard-divider' />
            <div className="dashboard-controls-container">
                <FilterButton checkboxData={checkboxData} onCheckboxSelection={handleFilterChange} eventType={"campaign"} />
                <DatePickerButton onDateChange={handleFilterDatesChanged} />
            </div>

            <div className="dashboard-header">
                <div className="dashboard-header-cards-container">

                    {headerValues && headerValues.map((data, index) => (
                        <TotalCards key={index} {...data} />
                    ))}
                </div>
                <div className="campaign-detail-buttons-container">
                    <EndChallengeButton campaign={true} />
                    <ChallengeAnalysisButton campaign={true}  />
                </div>
            </div>

            <div className="dashboard-divider" />
            <div className="dashboard-challenges-wrapper">
                <div className="dashboard-challenges-controls-container">
                    <GridListSwitchButton onSelectView={handleViewSelect} />
                    <div className="dashboard-challenges-search-container">
                        <img className="dashboard-challenges-search-icon" src={SearchIcon} alt="search" />
                        <input className="dashboard-challenges-search" type="text" placeholder="search" />
                    </div>

                </div>
            </div>
            <div className="dashboard-challenges-container ">

                {viewType === 'GRID' && campaignColumns && campaignsList && (
                    Array.isArray(campaigns) ? (
                        <div className="dashboard-challenges-grid-container">
                            {campaigns.map((data, index) => (
                                <CampaignCard
                                    key={index}
                                    campaignData={data}
                                />
                            ))}
                        </div>
                    ) : (
                        <div>

                            <p>Henüz kampanya yok.</p>
                        </div>
                    )

                )}

                {viewType === 'LIST' && campaignColumns && campaignsList && (
                    <div>
                        <DynamicTable
                            columns={campaignColumns}
                            data={campaignsList}
                            tableName={t('sotierList')}
                        />
                    </div>
                )}

            </div>

        </div>
    );
}
export default CorporationCampaignDetail;