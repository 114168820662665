import React, { useState } from "react";
import InputContainer from "../../../../utils/inputContainer";
import { useTranslation } from "react-i18next";
import { LoyaltyLevelInput } from "./LoyaltyLevelInput";
const DefaultSotyCoinCampaign = () => {
  const { t } = useTranslation("brandAdminProfile");
  const [value, setValue] = useState(10);
  const hasError = (value) => {
    if (value === "" || value === undefined || value < 10) {
      return t("valueCannotBeLessThan10"); // Error message if value is invalid
    }
    return false; // No error
  };

  const submit = () => {};
  return (
    <InputContainer
      title={t("firstCampaign")}
      titleDescription={t("firstCampaignDescription")}
    >
      <LoyaltyLevelInput
        title={t("sotyCoin")}
        adornment="%"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        error={hasError(value)}
      />
      <div className="form-button-container">
        <button
          className="brand-admin-input-area invoice-button"
          onClick={submit}
        >
          {t("save")}
        </button>
      </div>
    </InputContainer>
  );
};

export default DefaultSotyCoinCampaign;
