import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import NavigationBar from "../../../../../utils/navigation_bar/navigationBar";
import { useTranslation } from "react-i18next";
import SocialMediaButtonContainer from "../../../../../utils/sotier_list/socialMediaButtonContainer";
import "../../../../../../assets/css/SotierList.css";
import InstagramIcon from "../../../../../../assets/media/instagram-flat.png";
import FacebookIcon from "../../../../../../assets/media/facebook-flat.png";
import CreateCampaignButton from "../../../../../button/createCampaignButton";
import CallAPI from "../../../../../api/callApi";
import axios from "axios";
import { apiUrl, imagesUrl } from "../../../../../../config";
import { toast } from "react-toastify";
import {
  getCookie,
  getUserTypeFromToken,
  isMyTokenExpired,
} from "../../../../auth/tokenUtils";
import SotierListTable from "../../../../../tables/sotierListTable";
import placeholderImg from "../../../../../../assets/media/user.png";
import Loader from "../../../../../popup/loader";
import { metabaseUrl } from '../../../../../../config';

const SotierList = () => {
  const { t } = useTranslation("sotierList");
  const socialMediaArray = [
    { name: "INSTAGRAM", icon: InstagramIcon },
    { name: "FACEBOOK", icon: FacebookIcon },
  ];
  const navigate = useNavigate();
  const [provider, setProvider] = useState(socialMediaArray[0]);
  const [loaderState, setLoaderState] = useState(false);
  const [uuid, setUuid] = useState(false);
  const [sotierList, setSotierList] = useState([]);
  const [sotyCoinColumnsActive, setSotyCoinColumnsActive] = useState(false);
  const locationArray = [[t("sotierList"), "/brand/srm/sotier/list"]];
  const token = getCookie("access_token");

  const onSocialMediaTypeSelect = (socialMedia) => {
    setProvider(socialMedia);
  };

  const fetchUUID = async () => {
    const endpoint = apiUrl + "/api/v1/brand-admin/brand";
    try {
      setLoaderState(true);
      const response = await axios.get(endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setLoaderState(false);
      setUuid(response.data.responseData.uuid);
    } catch (error) {
      setLoaderState(false);
      toast.error(error.message);
      console.error("Error fetching data:", error);
    }
  };
  const FetchSotiers = async () => {
    setLoaderState(true);

    const url = `${metabaseUrl}/api/public/card/8a0dbd1d-3aa7-471b-a072-65371e408d6e/query/json?parameters=[{"type":"category","target":["variable",["template-tag","brand_uuid"]],"value":"${
      uuid ? uuid : " "
    }"},{"type":"category","target":["variable",["template-tag","provider"]],"value":"${
      provider ? provider.name : "INSTAGRAM"
    }"}]`;

    const response = await axios.get(url, {});
    console.log(response);
    const updatedData = response.data.map((item) => {
      const user = item;
      setLoaderState(false);
      let level;
      if (provider.name === "INSTAGRAM") {
        switch (true) {
          case user.used_campaign_count === 1:
            level = 1;
            break;
          case user.used_campaign_count > 1 && user.used_campaign_count <= 5:
            level = 2;
            break;
          case user.used_campaign_count > 5 && user.used_campaign_count <= 10:
            level = 3;
            break;
          case user.used_campaign_count > 10 && user.used_campaign_count <= 20:
            level = 4;
            break;
          case user.used_campaign_count > 20:
            level = 5;
            break;
          default:
            level = 0;
            break;
        }
      } else if (provider.name === "FACEBOOK") {
        switch (true) {
          case user.used_campaign_count === 1:
            level = 1;
            break;
          case user.used_campaign_count > 1 && user.used_campaign_count <= 5:
            level = 2;
            break;
          case user.used_campaign_count > 5:
            level = 3;
            break;
          default:
            level = 0;
            break;
        }
      }

      return {
        sotier: (
          <div
            className="sotier-list-sotier-container"
            onClick={() => {
              console.log(user);
              navigate(`/brand/srm/sotier/profile/${user.sotier_id}`);
            }}
          >
            <img
              className="sotier-list-sotier-image"
              src={user.logo ? imagesUrl + "/" + user.logo : placeholderImg}
              alt="logo"
            />
            <div className="sotier-list-sotier-details-container">
              <div className="sotier-list-sotier-details-user-name">
                {user.user_name}
              </div>
              <div className="sotier-list-sotier-details-full-name">
                {user.first_name + " " + user.last_name}
              </div>
              <div className="sotier-list-sotier-details-id">
                ID : {user.sotier_id}
              </div>
            </div>
          </div>
        ),
        social_media_level: <span>{level || 0}</span>,
        interaction_ratio: (
          <span>
            {parseFloat(parseFloat(user.interaction_ratio).toFixed(2)) || 0}
          </span>
        ),
        participation_count: <span>{user.participation_count || 0}</span>,

        loyalty_level: <span>{user.loyalty_level || 0}</span>,
        campaign_participation_count: (
          <span>{user.used_campaign_count || 0}</span>
        ),

        payment_amount: <span>{user.payment_amount || 0}</span>,

        total_soty_coins: <span>{user.total_soty_coins || 0}</span>,

        total_used_coins: <span>{user.total_used_coins || 0}</span>,
        total_remaining_coins: <span>{user.total_remaining_coins || 0}</span>,
        sotier_refund_coin_amount: (
          <span>{user.sotier_refund_coin_amount || 0}</span>
        ),

        last_spending_date: (
          <span>
            {user.last_spending_date
              ? new Date(user.last_spending_date).toLocaleString()
              : 0}
          </span>
        ),
      };
    });
    setSotierList(updatedData);
    console.log(response.data);
  };

  useEffect(() => {
    fetchUUID();
  }, []);

  useEffect(() => {
    if (uuid) {
      FetchSotiers();
    }
  }, [uuid, provider]);

  const baseColumns = [
    {
      title: t("sotier"),
      field: "sotier",
      className: "sotier sotier-list-column",
    },
    {
      title: t("socialMediaLevel"),
      field: "social_media_level",
      className: "sotier-list-column sotier-list-social-media-column",
    },
    {
      title: t("interactionRatio"),
      field: "interaction_ratio",
      className: "sotier-list-column sotier-list-social-media-column",
    },
    {
      title: t("participationCount"),
      field: "participation_count",
      className: "sotier-list-column sotier-list-social-media-column",
    },
    {
      title: t("loyaltyLevel"),
      field: "loyalty_level",
      className: "sotier-list-column",
    },
    {
      title: t("campaignParticipationCount"),
      field: "campaign_participation_count",
      className: "sotier-list-column",
    },
    {
      title: t("paymentAmount"),
      field: "payment_amount",
      className: "sotier-list-column",
    },
    {
      title: t("totalSotyCoins"),
      field: "total_soty_coins",
      className: `sotier-list-column ${
        sotyCoinColumnsActive ? "sotier-list-social-media-column" : ""
      }`,
      toggle: true,
      onToggleClick: () => setSotyCoinColumnsActive(!sotyCoinColumnsActive),
    },
  ];

  const coinColumns = [
    {
      title: t("totalUsedCoins"),
      field: "total_used_coins",
      className: "sotier-list-column  sotier-list-social-media-column",
    },
    {
      title: t("totalRemainingCoins"),
      field: "total_remaining_coins",
      className: "sotier-list-column  sotier-list-social-media-column",
    },
    {
      title: t("sotierRefundCoinAmount"),
      field: "sotier_refund_coin_amount",
      className: "sotier-list-column  sotier-list-social-media-column",
    },
  ];

  const userStatsColumns = [
    ...baseColumns,
    ...(sotyCoinColumnsActive ? coinColumns : []),
    {
      title: t("lastSpendingDate"),
      field: "last_spending_date",
      className: "sotier-list-column",
    },
  ];

  return (
    <div className="dashboard-container">
      {loaderState && <Loader />}
      <NavigationBar locationArray={locationArray} />
      <div className="sotier-list-top-container">
        <SocialMediaButtonContainer
          socialMediaArray={socialMediaArray}
          InstagramIcon={InstagramIcon}
          onSelect={(socialMedia) => {
            onSocialMediaTypeSelect(socialMedia);
          }}
        />
        <CreateCampaignButton />
      </div>

      <SotierListTable
        socialProvider={provider}
        rowClassName={"border-top-none"}
        columns={userStatsColumns}
        data={sotierList && sotierList}
      />
    </div>
  );
};

export default SotierList;
